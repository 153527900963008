import React, {useRef} from "react";
import Layout from "@components/layout";
import {useIsVisible} from "react-is-visible";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "use-media-query-hook";

const headers = [
	{
		name: "Age Restriction",
		href: "ageRestriction",
	},
	{
		name: "Intellectual Property",
		href: "intellectualProperty",
	},
	{
		name: "License to Use Our Website",
		href: "licenseToUseOurWebsite",
	},
	{
		name: "Who May Use Our Services?",
		href: "whoMayUseOurServices",
	},
	{
		name: "Warranty Disclaimer",
		href: "warrantyDisclaimer",
	},
	{
		name: "Limitation of Liability",
		href: "limitationOfLiability",
	},
	{
		name: "Indemnification",
		href: "indemnification",
	},
	{
		name: "Breaches of these Terms",
		href: "breachesOfTheseTerms",
	},
	{
		name: "Cookies",
		href: "cookies",
	},
	{
		name: "Governing Law",
		href: "governingLaw",
	},
	{
		name: "General",
		href: "general",
	},
];

const TermsPage = ({location}) => {
	const headerRef = useRef();
	const isHeaderVisible = useIsVisible(headerRef);

	const bottomRef = useRef();
	const isBottomVisible = useIsVisible(bottomRef);

	const params = new URLSearchParams(location.search);
	const hideHeader = params.get("hide_header");
	const hideFooter = params.get("hide_footer");

	const hideChat = params.get("hide_chat");

	if (hideChat === "true") {
		window.onload = function (e) {
			var tidioChat = document.getElementById("tidio-chat");
			if (tidioChat) {
				tidioChat.style.display = "none";
			}
		};
	}

	const isMobile = useMediaQuery("(max-width: 850px)");

	return (
		<Layout
			pageTitle="Terms and Conditions"
			hideHeader={hideHeader}
			hideFooter={hideFooter}
			footerRef={bottomRef}
			headerBg="bg-purple-50">
			<section className="relative bg-purple-50 py-5" ref={headerRef}>
				<div className="relative container pt-12 px-4 mb-20 mx-auto text-center">
					<h2 className="mt-8 mb-8 lg:mb-12 text-3xl lg:text-4xl font-semibold">
						Platnova Terms and Conditions
					</h2>
				</div>
			</section>

			<section className="relative">
				<div className="container mx-auto px-4">
					<div className="flex flex-wrap -mx-3">
						{hideHeader !== "true" && (
							<div className="w-full z-10 sm:w-1/3 px-3 pt-10 lg:pt-20 pb-10">
								<ul
									className={`mr-4 md:mr-0 mb-4 
								${!isMobile ? (isHeaderVisible ? "" : "fixed top-20") : ""} 
								${isBottomVisible ? "hidden" : ""}`}>
									{headers.map((item, index) => (
										<li className="mb-5" key={index}>
											<AnchorLink
												offset="80"
												className="inline-block pl-4 text-sm font-medium"
												href={`#${item.href}`}>
												{item.name}
											</AnchorLink>
										</li>
									))}
								</ul>
							</div>
						)}
						<div
							className={`w-full ${
								hideHeader !== "true" ? "sm:w-2/3" : "sm:w-3/3"
							}  px-3`}>
							<div className="flex flex-wrap px-3">
								<div className="mt-8 mb-8">
									These Website Terms and Conditions (“Terms”) contained herein
									on this webpage, shall govern your access to and use of this
									website, including all pages within this website (collectively
									referred to as this “Website”).
								</div>

								<div className="mb-8">
									These Terms apply in full force and effect to your use of this
									Website and by using this Website, you expressly accept all
									terms and conditions contained herein in full. You must not
									use this Website, if you have any objection to any of these
									Terms.
								</div>
								<div className="mb-8">
									PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY
									BEFORE BEING AGREED TO BE BOUND BY ITS TERMS.
								</div>

								<div className="mb-8" id="ageRestriction">
									<h2 className="font-bold mb-3">Age Restriction</h2>
									<div className="mb-5">
										In order to use our services, you must be over 18.
									</div>
								</div>

								<div className="mb-8" id="intellectualProperty">
									<h2 className="font-bold mb-3">Intellectual Property</h2>
									<div className="mb-5">
										Unless otherwise stated, Platnova Inc.(collectively,
										Platnova) and/or its licensors own the intellectual property
										rights and materials on the website subject to the license
										below. We do not grant you any right, license, title or
										interest to any of our intellectual Property rights which
										you may or may not have access to. You agree to take such
										actions including any legal or official document or other
										documents that may be needed to further affirm our
										intellectual property rights.
									</div>
								</div>

								<div className="mb-8" id="licenseToUseOurWebsite">
									<h2 className="font-bold mb-3">License to Use Our Website</h2>
									<div className="mb-5">
										We grant you a non-assignable, non-exclusive and revocable
										license to use the software provided as part of our services
										in the manner permitted by these Terms. This license grant
										includes all updates, upgrades, new versions and replacement
										software for you to use in connection with our services.
									</div>
									<div className="mb-5">
										The services are protected by copyright, trademark, and
										other laws of both the United States and foreign countries.
										Nothing in this Term gives you a right to use the Platnova
										name or any of Platnova’s trademarks, logos, domain names,
										and other distinctive brand features. All right, title and
										interest in and to the services are and will remain the
										exclusive property of Platnova and its licensors.
									</div>

									<div className="mb-5">
										If you do not comply with all the provisions, then you will
										be liable for all resulting damages suffered by you,
										Platnova Inc. and all third parties. Unless otherwise
										provided by applicable law, you agree not to alter,
										re-design, reproduce, adapt, display, distribute, translate,
										disassemble, reverse engineer, or otherwise attempt to
										create any source code that is derived from the software.
									</div>

									<div className="mb-5">
										Any feedback, comments, or suggestions you may provide to us
										and our services is entirely voluntary and we will be free
										to use such feedback, comments or suggestion as we see fit
										without any obligation to you.
									</div>
								</div>

								<div className="mb-8" id="whoMayUseOurServices">
									<h2 className="font-bold mb-3">Who May Use Our Services?</h2>
									<div className="mb-5">
										You may use the Services only if you agree to form a binding
										contract with Platnova and are not a person barred from
										receiving services under the laws of the applicable
										jurisdiction. If you are accepting these Terms and using the
										Services on behalf of a company, business, or organization,
										you represent and warrant that you are authorized to do so.
									</div>
								</div>

								<div className="mb-8" id="warrantyDisclaimer">
									<h2 className="font-bold mb-3">Warranty Disclaimer</h2>
									<div className="mb-5">
										Platnova WILL ALWAYS ENSURE THAT THE WEBSITE IS AVAILABLE AT
										ALL TIMES AND BUG FREE. HOWEVER, IT IS USED AT YOUR OWN
										RISK.
									</div>
									<div className="mb-5">
										WE PROVIDE ALL MATERIALS “AS IS” WITH NO WARRANTY, EXPRESS
										OR IMPLIED, OF ANY KIND. WE EXPRESSLY DISCLAIM ANY AND ALL
										WARRANTIES AND CONDITIONS, INCLUDING ANY IMPLIED WARRANTY OR
										CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
										PURPOSE, AVAILABILITY, SECURITY, TITLE, AND NON-INFRINGEMENT
										OF INTELLECTUAL PROPERTY RIGHTS, WITHOUT LIMITING THE
										GENERALITY OF THE FOREGOING, RAVEPAY MAKES NO WARRANTY THAT
										OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT
										OUR WEBSITE WILL REMAIN FREE FROM ANY INTERRUPTION, BUGS,
										INNACCURACIES, AND ERROR FREE.
									</div>
									<div className="mb-5">
										YOUR USE OF OUR SERVICES ARE AT YOUR OWN RISK AND YOU ALONE
										WILL BE RESPONSIBLE FOR ANY DAMAGE THAT RESULTS IN LOSS OF
										DATA OR DAMAGE TO YOUR COMPUTER SYSTEM. NO ADVICE OR
										INFORMATION, WHETHER ORAL OR WRITTEN OBTAINED BY YOU FROM
										OUR WEBSITE OR OUR SERVICES WILL CREATE ANY WARRANTY OR
										CONDITION NOT EXPRESSLY STATED.
									</div>
								</div>

								<div className="mb-8" id="limitationOfLiability">
									<h2 className="font-bold mb-3">Limitation of Liability</h2>
									<div className="mb-5">
										YOU AGREE TO THE LIMITATION LIABILITY CLAUSE TO THE MAXIMUM
										EXTENT PERMITTED BY APPLICABLE LAW: Platnova WILL IN NO WAY
										BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL PUNITIVE,
										CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES OR ANY DAMAGES
										INCLUDING DAMAGES RESULTING FROM REVENUE LOSS, PROFIT LOSS,
										USE, DATA, GOODWILL , BUSINESS INTERRUPTION OR ANY OTHER
										INTANGIBLE LOSSES (WHETHER Platnova HAS BEEN ADVISED OF THE
										POSSIBILITY OF SUCH DAMAGES OR NOT) ARISING OUT OF
										Platnova’S WEBSITE OR SERVICES (INCLUDING, WITHOUT
										LIMITATION TO INABILITY TO USE, OR ARISING FROM THE RUSULT
										OF USE OF Platnova’S WEBSITE OR SERVICES) WHETHER SUCH
										DAMAGES ARE BASED ON WARRANTY, TORT, CONTRACT, STATUTE OR
										ANY OTHER LEGAL THEORY.
									</div>

									<div className="mb-5">
										SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN
										WARRANTIES OR LIMITATIONS ON THE SCOPE AND DURATION OF SUCH
										WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU IN
										THEIR ENTIRITIES, BUT WILL APPLY TO THE MAXIMUM EXTENT
										PERMITTED BY APPLICABLE LAW.
									</div>
								</div>

								<div className="mb-8" id="indemnification">
									<h2 className="font-bold mb-3">Indemnification</h2>
									<div className="mb-5">
										You hereby indemnify Platnova and undertake to keep Platnova
										indemnified against any losses, damages, costs, liabilities
										and expenses (including without limitation to reasonable
										legal fees) arising out of any breach by you of any
										provision of these Terms, or arising out of any claim that
										you have breached any provision of these Terms.
									</div>
								</div>

								<div className="mb-8" id="breachesOfTheseTerms">
									<h2 className="font-bold mb-3">Breaches of these Terms</h2>
									<div className="mb-5">
										Without prejudice to Platnova’s other rights under these
										Terms, if you breach these Terms in any way, Platnova may
										take such action as Platnova deems appropriate to deal with
										the breach, including suspending your access to the website,
										prohibiting you from accessing the website, blocking
										computers using your IP address from accessing the website,
										contacting your internet service provider to request that
										they block your access to the website and/or bringing court
										proceedings against you.
									</div>
								</div>

								<div className="mb-8" id="cookies">
									<h2 className="font-bold mb-3">Cookies</h2>
									<div className="mb-5">
										Like many other websites, we use cookies to identify you as
										a user and to customize and improve our services. A cookie
										is a small data file that is transferred to your computer or
										mobile device. It enables us to remember your account log-in
										information, IP addresses, web traffic, number of times you
										visit, date and time of visits.
									</div>
									<div className="mb-5">
										Some browsers may automatically accept cookies while some
										can be modified to decline cookies or alert you when a
										website wants to place a cookie on your computer. If you do
										choose to disable cookies, it may limit your ability to use
										our website.
									</div>
								</div>

								<div className="mb-8" id="governingLaw">
									<h2 className="font-bold mb-3">Governing Law</h2>
									<div className="mb-5">
										These Terms shall be interpreted and governed in accordance
										with the Laws of the State of California and you submit to
										the non-exclusive jurisdiction of the State and Federal
										Courts located in California for the resolution of any
										dispute.
									</div>
								</div>

								<div className="mb-8" id="general">
									<h2 className="font-bold mb-3">General</h2>
									<div className="mb-5">
										We may revise these Terms from time to time. The changes
										will not be retroactive, and the most current version of the
										Terms which will always be on platnova.com/terms will govern
										our relationship with you. We will also try to notify you of
										any material changes which could be done via email
										associated with your account or service notification. By
										continuing to use our services after the changes become
										effective, you agree to be bound by the revised Terms.
									</div>
									<div className="mb-5">
										In the event that any of these Terms is held to be invalid
										or unenforceable, then that provision will be limited or
										eliminated to the minimum extent necessary, and the
										remaining provisions of these Terms will remain in full
										effect. Platnova's failure to enforce any right or provision
										of these Terms will not be deemed a waiver of such right or
										provision.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default TermsPage;
